import { createRouter, createWebHistory } from 'vue-router'
// import HelloWorld from './components/HelloWorld.vue'
// import SelfPaced from './components/SelfPaced.vue'
// import FlankerTask from './components/FlankerTask.vue'
// import DigitSpanMV from './components/DigitSpanMV.vue'
// import DigitSpanFvH from './components/DigitSpanFvH.vue'
// import NavigationButtons from './components/MainMenu.vue'
// import DigitSpanMenu from './components/DigitSpanMenu.vue'
// import ReadingSpanMenu from './components/ReadingSpanMenu.vue'
// import ReadingSpan from './components/ReadingSpan.vue'
import CourseOne from './components/CourseOne.vue'
import CourseMenu from './components/CourseMenu.vue'


const routes = [
    // {
    //     name: 'HelloWorld',
    //     component: HelloWorld,
    //     path: '/'
    // },
    {
        name: 'CourseOne',
        component: CourseOne,
        path: '/course-one/:currentid/list/:currentlist',
        props: true
    },
    {
        name: 'CourseMenu',
        component: CourseMenu,
        path: '/',
    }
]

const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
)

export default router
