<template>
  <!-- <div v-if="showFeedback" class="feedback-container">
    <p>{{ feedback }}</p>
  </div> -->

  <div class="container">
    <div v-if="showOptions" class="button-grid">
      <button :class="{ selected: selectedOption === answer1[trialIndex] }" @click="selectOption(answer1[trialIndex])">
        {{ answer1[trialIndex] }}
      </button>
      <button :class="{ selected: selectedOption === answer2[trialIndex] }" @click="selectOption(answer2[trialIndex])">
        {{ answer2[trialIndex] }}
      </button>
      <button :class="{ selected: selectedOption === answer3[trialIndex] }" @click="selectOption(answer3[trialIndex])">
        {{ answer3[trialIndex] }}
      </button>
      <button :class="{ selected: selectedOption === answer4[trialIndex] }" @click="selectOption(answer4[trialIndex])">
        {{ answer4[trialIndex] }}
      </button>
      <button :class="{ selected: selectedOption === answer5[trialIndex] }" @click="selectOption(answer5[trialIndex])">
        {{ answer5[trialIndex] }}
      </button>
      <button :class="{ selected: selectedOption === answer6[trialIndex] }" @click="selectOption(answer6[trialIndex])">
        {{ answer6[trialIndex] }}
      </button>
    </div>
  </div>
</template>

<script>
var instructions1 = `
<div class="instructions" style="text-align: left;">
<h3>Perception task.</h3> 
<p>In this task, you will listen to several utterances and identify two things:</p>
<ol>
<li>The word (or syllable) that carries the nuclear accent (<i>i.e.</i>, the last and most prominent accent).</li>
<li>The tone used in that word—either falling or rising.</li>
</ol><br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var instructions2a = `
<div class="instructions" style="text-align: left;">
<p>For example, on the next screen you will hear: "She planted a tree".</p>
<br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var instructions2b = `
<div class="instructions" style="text-align: left;">
<p>For this sentence, the options will be:</p>
<ol type="A">
<li>Planted - falling.</li>
<li>Planted - rising.</li>
<li>Tree - falling.</li>
<li>Tree - rising.</li>
</ol>
<p>In this case, the nuclear accent falls on "tree" with a falling tone. Therefore, the correct answer is: C. Tree - falling.</p><br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var instructions3a = `
<div class="instructions" style="text-align: left;">
<p>Another example:</p>
<p>On the next screen, you will hear: “Is that your car?”</p>
<br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var instructions3b = `
<div class="instructions" style="text-align: left;">
<p>For this sentence, the options will be:</p>
<ol type="A">
<li>That - falling.</li>
<li>That - rising.</li>
<li>Car - falling.</li>
<li>Car - rising.</li>
</ol>
<p>Here, the nuclear accent is on "car", with a rising tone. Therefore, the correct answer is: D. Car - rising.</p><br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var instructions4 = `
<div class="instructions" style="text-align: left;">
<p>Select your answer with the mouse, and then press SPACEBAR. You will have a maximum of 30 seconds
  to make your choice.</p>
<p>Warning: If you press SPACEBAR before you click on the answer, this will not be recorded
  and the item will be classified as wrong.</p>
<p>Now, the experiment will begin.</p><br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var instructions5a = `
<div class="instructions" style="text-align: left;">
<h2>Instructions</h2>
<p>This test consists of two tasks: a production task and a perception task.</p>
<h2>Production task</h2>
<p>In this part of the test, please read aloud the following utterances as naturally as possible,
  after pressing the SPACEBAR.</p>
<p>If you make a mistake, you may read the sentence again. The last complete reading will be used
  for scoring and review purposes.</p><br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var instructions5b = `
<div class="instructions" style="text-align: left;">
<p>After you have recorded the sentence, press the DONE button. You will have a maximum of
  30 seconds to record the sentence.</p>
<p>Warning: If you press the DONE button before you have recorded the sentence,
  your answer will not be recorded and the item will be classified as incorrect.</p>
<p>Now, the experiment will begin.</p>
<br />
<p style="text-align: center;">Press SPACEBAR to continue.</p>
</div>
`;

var intermezzo = `
<div class="instructions" style="text-align: left;">
<p>This is the end of the production test.</p><br />
<p style="text-align: center;">Press SPACEBAR to continue to the perception test.</p>
</div>
`;

var ending = `
<div class="instructions" style="text-align: left;">
<h2>This is the end of the test.</h2>
<p>Thank you</p><br />
<p style="text-align: center;">Press SPACEBAR to finish.</p>
</div>
`;

import { initJsPsych } from "jspsych";
import "jspsych/css/jspsych.css";
import htmlKeyboardResponse from "@jspsych/plugin-html-keyboard-response";
import audioKeyboardResponse from "@jspsych/plugin-audio-keyboard-response";
import htmlAudioResponse from "@jspsych/plugin-html-audio-response";
import jsPsychPreload from "@jspsych/plugin-preload";
import jsPsychInitializeMicrophone from "@jspsych/plugin-initialize-microphone";

// import contrastive_focus from '@/assets/json/contrastive_focus.json'
import { randomizeAndConcatenate } from "@/utils/jsonHelper";
const perceptionStimuli = randomizeAndConcatenate();
const productionStimuli = randomizeAndConcatenate();

export default {
  name: "CourseOne",
  props: {
    currentid: String,
    currentlist: Number,
  },
  data() {
    return {
      showOptions: false,
      selectedOption: null,
      timeline: [],
      jsPsych: initJsPsych({ on_finish: this.on_finish }),
      answer1: [],
      answer2: [],
      answer3: [],
      answer4: [],
      answer5: [],
      answer6: [],
      perc_audio_stim: [],
      correct_answer: [],
      number: [],
      sentence: [],
      type: [],
      prod_audio_stim: [],
      correct_answer2: [],
      number2: [],
      sentence2: [],
      type2: [],
      trialIndex: 0,
      feedback: "",
      audio_instruction1: null,
      audio_instruction2: null
      // showFeedback: true,
    };
  },
  created() {
    this.answer1 = perceptionStimuli.map((item) => item.answer1);
    this.answer2 = perceptionStimuli.map((item) => item.answer2);
    this.answer3 = perceptionStimuli.map((item) => item.answer3);
    this.answer4 = perceptionStimuli.map((item) => item.answer4);
    this.answer5 = perceptionStimuli.map((item) => item.answer5);
    this.answer6 = perceptionStimuli.map((item) => item.answer6);
    this.number = perceptionStimuli.map((item) => item.number);
    this.sentence = perceptionStimuli.map((item) => item.sentence);
    this.correct_answer = perceptionStimuli.map((item) => item.correct_answer);
    this.type = perceptionStimuli.map((item) => item.type);
    this.perc_audio_stim = perceptionStimuli.map((item) =>
      require(`@/assets/audio/${item.audio_file}`)
    );
    this.sentence2 = productionStimuli.map((item) => item.sentence);
    this.number2 = productionStimuli.map((item) => item.number);
    this.correct_answer2 = productionStimuli.map((item) => item.correct_answer);
    this.type2 = productionStimuli.map((item) => item.type);
    this.prod_audio_stim = productionStimuli.map((item) =>
      require(`@/assets/audio/${item.audio_file}`)
    );
    this.audio_instruction1 = require(`@/assets/examples/She planted a tree.wav`);
    this.audio_instruction2 = require(`@/assets/examples/Is that your car.wav`);
  },
  methods: {
    on_finish() {
      // console.log(this.jsPsych.data.displayData("csv"));
      this.addProperties();
      // this.jsPsych.data.get().localSave("csv", "data.csv");
      var experimental_trials = this.jsPsych.data
        .get()
        .filter({ type: "experimental" });
      this.postData(experimental_trials);
      // this.openNextTask()
    },
    // openNextTask() {
    //   this.$router.push({ name: 'FlankerTask', params: { currentid: this.currentid, currentlist: this.currentlist } })
    // },
    addProperties() {
      this.jsPsych.data.addProperties({
        ID: this.currentid,
        list: this.currentlist,
      });
    },
    preload() {
      this.timeline.push({
        type: jsPsychPreload,
        auto_preload: true,
      });
    },
    selectOption(option) {
      this.selectedOption = option;
      console.log("Button press: ", this.selectedOption);
    },
    perceptionTrials(index) {
      this.timeline.push({
        type: audioKeyboardResponse,
        stimulus: this.perc_audio_stim[index],
        trial_duration: 30000,
        response_ends_trial: true,
        trial_ends_after_audio: false,
        response_allowed_while_playing: true,
        choices: " ",
        prompt:
          "<p>Listen to the audio and select the correct choice.</p><br /><p>Then, press SPACEBAR to continue.</p>",
        data: {
          condition: this.type[index],
          stim: this.sentence[index],
          expected_answer: this.correct_answer[index],
          // answer: this.selectedOption,
          environment: "course",
          type: "experimental",
          mode: "perception",
        },
        on_finish: (data) => {
          data.response = this.selectedOption;
          console.log("Finished!");
          if (this.selectedOption == this.correct_answer[this.trialIndex]) {
            this.feedback = "RIGHT";
          } else {
            this.feedback = "WRONG";
          }
          // this.showFeedback = true;
          this.showOptions = false;
          console.log("Trial index: " + this.trialIndex);
          console.log("Category: " + this.type[this.trialIndex]);
          console.log("Sentence: " + this.sentence[this.trialIndex]);
          console.log("Right option: " + this.correct_answer[this.trialIndex]);
          console.log("Selected option: " + this.selectedOption);
          console.log("Feedback: " + this.feedback);
        },
      });
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: "<p>Press SPACEBAR to continue.</p>",
        choices: " ",
        on_finish: () => {
          this.showOptions = true;
          // this.showFeedback = false;
          this.selectedOption = "";
          this.trialIndex++;
          console.log("Finished!");
        },
      });
    },
    makePerceptionTrials() {
      for (var i = 0; i < this.sentence.length; i++) {
        this.perceptionTrials(i);
      }
    },
    makeProductionTrials() {
      for (var i = 0; i < this.sentence.length; i++) {
        this.productionTrials(i);
      }
    },
    runExperiment() {
      this.jsPsych.run(this.timeline);
    },
    makeFirstInstructions() {
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions1,
        choices: " ",
        data: {
          type: "instructions",
        },
      });
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions2a,
        choices: " ",
        data: {
          type: "instructions",
        },
      });
      this.timeline.push({
        type: audioKeyboardResponse,
        stimulus: this.audio_instruction1,
        response_ends_trial: true,
        trial_ends_after_audio: false,
        choices: " ",
        prompt:
          "<p>Press SPACEBAR to continue.</p>",
        data: {
          condition: 'instruction audio',
          stim: 'instruction audio',
          expected_answer: 'none',
          environment: "course",
          type: "instruction",
          mode: "instrucion",
        }
      });
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions2b,
        choices: " ",
        data: {
          type: "instructions",
        },
      });
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions3a,
        choices: " ",
        data: {
          type: "instructions",
        },
      });
      this.timeline.push({
        type: audioKeyboardResponse,
        stimulus: this.audio_instruction2,
        response_ends_trial: true,
        trial_ends_after_audio: false,
        choices: " ",
        prompt:
          "<p>Press SPACEBAR to continue.</p>",
        data: {
          condition: 'instruction audio',
          stim: 'instruction audio',
          expected_answer: 'none',
          environment: "course",
          type: "instruction",
          mode: "instrucion",
        }
      });
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions3b,
        choices: " ",
        data: {
          type: "instructions",
        },
      });
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions4,
        choices: " ",
        data: {
          type: "instructions",
        },
        on_finish: () => {
          this.showOptions = true;
          console.log("Finished!");
        },
      });
    },
    makeSecondInstructions() {
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions5a,
        choices: " ",
        data: {
          type: "instructions",
        },
        on_start: () => {
          this.showOptions = false;
        },
        on_finish: () => {
          this.showOptions = false;
          console.log("Finished!");
        },
      });
    this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: instructions5b,
        choices: " ",
        data: {
          type: "instructions",
        },
        on_start: () => {
          this.showOptions = false;
        },
        on_finish: () => {
          this.showOptions = false;
          console.log("Finished!");
        },
      });
    },
    makeIntermezzo() {
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: intermezzo,
        choices: " ",
        data: {
          type: "instructions",
        },
        on_start: () => {
          this.showOptions = false;
        },
        on_finish: () => {
          this.showOptions = false;
          console.log("Finished!");
        },
      });
    },
    productionTrials(index) {
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: "",
        prompt: "<p>Press SPACEBAR and read aloud the sentence on the next screen.</p>",
        choices: " ",
        data: {
          type: "instructions",
        },
        // on_finish: () => {
        //   this.showOptions = true;
        //   this.showFeedback = false;
        //   this.selectedOption = "";
        //   this.trialIndex++;
        //   console.log("Finished!");
        // },
      });
      this.timeline.push({
        type: htmlAudioResponse,
        stimulus: 'Recording...<br /><br /><b>' + this.sentence2[index] + '</b>',
        recording_duration: 30000,
        done_button_label: "Done",
        data: {
          condition: this.type2[index],
          stim: this.sentence2[index],
          expected_answer: this.correct_answer2[index],
          // response: this.selectedOption,
          environment: "course",
          type: "experimental",
          mode: "production",
        },
      });
    },
    initMic() {
      this.timeline.push({
        type: jsPsychInitializeMicrophone,
        button_label: "Use this microphone.",
      });
    },
    async postData(experimental_trials) {
      let url = "https://www.experimentosonline.cl:3000/markers/";
      // let url = 'http://127.0.0.1:3000/markers/'
      let data = {
        id: experimental_trials.select("ID"),
        rt: experimental_trials.select("rt"),
        stim: experimental_trials.select("stim"),
        response: experimental_trials.select("response"),
        type: experimental_trials.select("condition"),
        expected_answer: experimental_trials.select("expected_answer"),
        environment: experimental_trials.select("environment"),
        list: experimental_trials.select("type"),
      };
      // console.log(experimental_trials.select('trial_index'))
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => console.log(response.ok));
    },
    makeEnd() {
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: ending,
        choices: " ",
        data: {
          condition: "instruction",
          stim: " ",
          expected_answer: "na",
          type: "",
          environment: "span",
          trial: -1,
        },
        on_start: () => {
          this.showOptions = false;
        },
      });
    },
  },
  mounted() {
    this.preload();
    this.makeSecondInstructions();
    this.initMic();
    this.makeProductionTrials();
    this.makeIntermezzo();
    this.makeFirstInstructions();
    this.makePerceptionTrials();
    this.makeEnd();
    this.runExperiment();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.container {
  margin-top: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* Alinear texto al centro */
}

/*.feedback-container {
  height: 25px;
  margin-top: 20vh; 
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.feedback-container p {
  margin-top: 70vh;
  font-size: 28px;
} */
label {
  /* display: flex;  Usar flexbox para el contenido del label */
  align-items: center;
  /* Alinear el botón y el texto verticalmente */
  margin-bottom: 10px;
  /* Espacio entre cada opción */
}

instructions {
  position: absolute;
  top: 10%;
  left: 50%;
  /* transform: translateX(-50%) translateY(-50%); */
  text-align: left;
}

instructions h3 {
  font-weight: bold;
  /* top: 10%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); */
  text-align: left;
}

.selected {
  background-color: #4CAF50;
  /* Color de fondo verde */
  color: white;
  /* Texto en blanco */
}
</style>