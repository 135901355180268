import empty_word from "@/assets/json/empty_word.json";
import event_sentences from "@/assets/json/event_sentences.json";
import final_adverbials_time from "@/assets/json/final_adverbials_time.json";
import final_vocatives from "@/assets/json/final_vocatives.json";
import LLI_no_tail from "@/assets/json/LLI_no_tail.json";
import LLI_with_tail from "@/assets/json/LLI_with_tail.json";
import reflexive_pronouns from "@/assets/json/reflexive_pronouns.json";
import wh_questions from "@/assets/json/wh_questions.json";

// Función para randomizar y concatenar JSON
export function randomizeAndConcatenate() {
  var shuffled_empty_word = empty_word
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 3);

  var shuffled_event_sentences = event_sentences
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 4);

  var shuffled_final_adverbials_time = final_adverbials_time
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 2);

  var shuffled_final_vocatives = final_vocatives
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 2);

  var shuffled_LLI_no_tail = LLI_no_tail.map((value) => ({
    value,
    sort: Math.random(),
  }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 9);

  var shuffled_LLI_with_tail = LLI_with_tail.map((value) => ({
    value,
    sort: Math.random(),
  }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 10);

  var shuffled_reflexive_pronouns = reflexive_pronouns
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 2);

  var shuffled_wh_questions = wh_questions
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 3);

  var stimuli = shuffled_empty_word.concat(
    shuffled_event_sentences,
    shuffled_final_adverbials_time,
    shuffled_final_vocatives,
    shuffled_LLI_no_tail,
    shuffled_LLI_with_tail,
    shuffled_reflexive_pronouns,
    shuffled_wh_questions
  );

  return stimuli
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}
